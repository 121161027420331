import { createStore } from 'vuex';

const mutations = {

    updatePersonalization(state, payload) {
        state.personalization = {...state.personalization, ...payload};
    }

}
  

const store = createStore({

    state() {
        return {
            personalization: {
                names: '',
                wishes: '',
                authors: '',
                footer: ''
            }
        };
    },    
    mutations,
    //getters: {},
    //actions: {},
    //modules: {}

})

export default store