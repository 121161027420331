import { createRouter, createWebHistory } from 'vue-router'
//import AboutView from '../views/AboutView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    //component: HomeView
    //component: () => import('../App.vue')
    component: () => import('../views/DedicationsListView.vue')
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: AboutView
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/:tagLink',
    name: 'dedicationsByTagLink',
    //props: true,
    component: () => import('../views/DedicationsListView.vue'),
  },
  {
    path: '/szukaj/:searchValue',
    name: 'dedicationsBySearchValue',
    component: () => import('../views/SearchListView.vue'),
  },  
  {
    path: '/tekst/:dedicationLink',
    name: 'dedicationByLink',
    //props: true,
    component: () => import('../views/DedicationView.vue'),
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

// router.afterEach((to) => {
//   if (to.name == 'dedicationByLink') {
//   }
// });

export default router
