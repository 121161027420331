<template>


  <!-- @tag (inaczej v-on:tag) - reaguje na zmianę wartości "tag" emitowanej w PageHeader -->

  <PageHeader @searchValue="showSearchValue" />

  <main>

    <router-view v-slot="{ Component }">
      <transition name="fade">
        <div :key="route.name">  
          <component :is="Component" />
        </div>
      </transition>
    </router-view>

  </main>

  <PageFooter />

  <CookiesConsent />

</template>

<script setup>

  import PageHeader from '@/components/PageHeader.vue';
  import PageFooter from '@/components/PageFooter.vue';
  import CookiesConsent from '@/components/CookiesConsent.vue';

  import '@/assets/scss/normalize.scss';

  import { watch } from 'vue';
  import { useRoute } from 'vue-router';
  import { useRouter } from 'vue-router';

  const route = useRoute();
  const router = useRouter();

  const showSearchValue = searchValue => {
    router.push(`/szukaj/${searchValue}`)
  }

  //import { useHead } from '@unhead/vue'
  
  watch(route, () => {

    // useHead({
    //   title: 'Dedykacje na Chrzest, Komunię, Ślub i Rocznicę - Dedykacje.net',
    //   meta: [
    //     {
    //       name: 'description',
    //       content: 'Zbiór przykładowych dedykacji na okazje Chrztu Świętego, Pierwszej Komunii Świętej, Ślubu i Rocznicy do umieszczenia na pamiątkach, obrazkach srebrnych, kartkach, upominkach.',
    //     },
    //   ],    
    // })  

  });


</script>




<style lang="scss">

  @import url('https://fonts.googleapis.com/css2?family=Noto+Serif:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');  
  @import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');


  #app {

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @include font(1rem, 400, 1.3, $text-font-1, $text-color);
    background-color: $background-color;
    background-image: url(assets/images/bg.jpg);
    background-repeat: repeat;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;

  }

  main {

    max-width: $layout-max-width;
    width: $layout-width;
    margin: 0 auto;
    flex-grow: 2;
    position: relative;

  }


  h1 {
  
    @include font(2rem, 400, 1.5, $text-font-2, $lead-color);
    text-align: center;
    margin: 0 0 3rem 0;
    font-style: italic;
    letter-spacing: 0.1rem;
    transition: opacity $fade-duration;
    opacity: 1;    
  
    &.off {
        opacity: 0;
    }
    
  }

  .list {

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      column-gap: 5%;
      
      // display: grid;
      // grid-template-columns: repeat(4, 1fr);
      // grid-template-rows: masonry;      
      list-style-type: none;
      padding: 0;
      transition: opacity $fade-duration;
      opacity: 1;    

      &.off {
          opacity: 0;
      }
  }


  .fade-enter-active,
  .fade-leave-active {
    transition: opacity $fade-duration;
  }


  .fade-enter-to,
  .fade-leave-from {
    opacity: 1;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  } 



</style>






// const TAG_NAME_QUERY = gql`
//   query GetTagDetails($link: String) {
//     tags(where: { link: { equals: $link } })  {
//       name
//     }
//   }
// `

// watch(
  //      () => route.params.tagLink,
  //      () => {              
  // 				console.log(`App | watch | route tagLink: ${route.params.tagLink}`);
  //      }
  // )

  // const activeTagLink = computed(
  // 	() => route.params.tagLink
  // )

  // if (route.params.tagLink) {
    
  // 	const { result } = useQuery(TAG_NAME_QUERY, { link: route.params.tagLink });
    
  // 	watch(result, value => { 
  // 		tagName.value = value.tags[0].name          
  // 	})		

  // }

  // const reloadTagData = event => {
  //   tagName.value = event.name;
  // }