<template>

    <svg class="logo" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="399px" height="104px" viewBox="0 0 399 104">

    <g>
        <path fill="#DA705A" d="M362.084,35C343.03,35,325.979,50.446,324,69.5s7.946,34.5,27,34.5s45.021-22.946,47-42
            S381.139,35,362.084,35z"/>
    </g>

    <g>
        <path fill-rule="evenodd" clip-rule="evenodd" fill="#494965" d="M155.41,36.427l-1.702,2.927
            c-1.456,2.531-2.853,4.74-4.191,6.627c-1.338,1.888-2.66,3.458-3.966,4.713c-1.306,1.255-2.623,2.198-3.95,2.831
            c-1.328,0.633-2.708,0.949-4.143,0.949c-1.627,0-2.837-0.439-3.628-1.319c-0.792-0.879-1.188-2.059-1.188-3.538
            c0-0.665,0.069-1.389,0.209-2.172c0.139-0.782,0.321-1.598,0.546-2.444c0.225-0.848,0.487-1.705,0.787-2.574
            c0.299-0.868,0.599-1.721,0.899-2.558l8.189-22.518h-4.753l-6.966,19.157l-1.575,2.846c-1.413,2.553-2.783,4.777-4.11,6.676
            c-1.328,1.897-2.649,3.474-3.966,4.729c-1.317,1.255-2.649,2.188-3.998,2.799s-2.741,0.917-4.175,0.917
            c-1.648,0-2.863-0.439-3.645-1.319c-0.782-0.879-1.172-2.059-1.172-3.538c0-0.665,0.069-1.389,0.209-2.172
            c0.139-0.782,0.321-1.593,0.546-2.429c0.225-0.836,0.487-1.694,0.787-2.573c0.3-0.88,0.599-1.737,0.899-2.574l14.322-39.471
            h-4.752l-8.735,24.095c0-1.158-0.107-2.215-0.321-3.169c-0.214-0.954-0.573-1.77-1.076-2.445c-0.503-0.675-1.162-1.2-1.975-1.576
            c-0.814-0.375-1.831-0.563-3.051-0.563c-1.177,0-2.436,0.172-3.773,0.515c-1.338,0.343-2.698,0.863-4.078,1.56
            c-1.381,0.697-2.762,1.582-4.143,2.654s-2.698,2.349-3.95,3.828c-1.252,1.479-2.414,3.169-3.484,5.066
            c-1.044,1.852-1.943,3.905-2.702,6.154l-1.601,2.837c-1.071,1.888-2.323,3.737-3.757,5.55c-1.435,1.813-3.019,3.426-4.753,4.841
            c-1.734,1.416-3.618,2.558-5.651,3.427c-2.034,0.868-4.175,1.303-6.423,1.303c-2.697,0-4.672-0.66-5.924-1.979
            c-1.252-1.319-1.879-3.469-1.879-6.45c0-1.951,0.177-3.865,0.53-5.742c0.353-1.876,0.84-3.684,1.461-5.42
            c2.569,0,4.859-0.178,6.872-0.531c2.012-0.354,3.854-0.885,5.523-1.593c1.349-0.557,2.563-1.217,3.645-1.978
            c1.081-0.762,2.007-1.582,2.778-2.461c0.771-0.88,1.359-1.813,1.766-2.799c0.406-0.986,0.61-1.995,0.61-3.024
            c0-1.822-0.616-3.238-1.846-4.246c-1.231-1.008-2.917-1.512-5.058-1.512c-2.12,0-4.229,0.504-6.326,1.512
            c-2.099,1.008-4.057,2.402-5.876,4.182c-1.82,1.78-3.431,3.893-4.833,6.338c-1.358,2.367-2.414,4.941-3.179,7.711l-1.589,2.872
            c-1.413,2.553-2.783,4.777-4.11,6.676c-1.328,1.897-2.649,3.474-3.966,4.729c-1.317,1.255-2.649,2.188-3.998,2.799
            s-2.741,0.917-4.175,0.917c-1.649,0-2.863-0.439-3.645-1.319c-0.782-0.879-1.172-2.059-1.172-3.538
            c0-0.665,0.069-1.389,0.209-2.172c0.139-0.782,0.321-1.593,0.546-2.429c0.225-0.836,0.487-1.694,0.787-2.573
            c0.299-0.88,0.599-1.737,0.899-2.574L43.82,0.397h-4.752l-8.735,24.095c0-1.158-0.107-2.215-0.321-3.169
            c-0.214-0.954-0.573-1.77-1.076-2.445c-0.503-0.675-1.162-1.2-1.975-1.576c-0.814-0.375-1.831-0.563-3.051-0.563
            c-1.178,0-2.436,0.172-3.773,0.515c-1.338,0.343-2.698,0.863-4.079,1.56c-1.38,0.697-2.761,1.582-4.142,2.654
            s-2.698,2.349-3.95,3.828c-1.252,1.479-2.414,3.169-3.484,5.066c-1.071,1.898-1.991,4.005-2.762,6.321
            c-0.557,1.673-0.984,3.341-1.285,5.003c-0.299,1.662-0.449,3.243-0.449,4.744c0,1.395,0.139,2.676,0.417,3.845
            s0.717,2.188,1.316,3.056c0.599,0.869,1.365,1.544,2.296,2.027c0.932,0.482,2.061,0.724,3.388,0.724
            c2.826,0,5.608-1.175,8.349-3.522c2.74-2.349,5.512-5.882,8.317-10.6c-0.3,1.051-0.525,2.063-0.674,3.04
            c-0.15,0.976-0.225,1.882-0.225,2.718c0,1.266,0.171,2.413,0.514,3.442c0.342,1.029,0.851,1.909,1.525,2.638
            c0.674,0.729,1.509,1.292,2.504,1.688c0.996,0.396,2.146,0.596,3.452,0.596c1.67,0,3.244-0.322,4.721-0.965
            c1.477-0.644,2.922-1.625,4.335-2.944c1.413-1.318,2.831-2.991,4.255-5.018c1.424-2.027,2.917-4.424,4.48-7.19l0.015-0.026
            c-0.029,0.19-0.071,0.385-0.096,0.573c-0.161,1.223-0.241,2.381-0.241,3.475c0,4.011,1.049,7.029,3.147,9.056
            s5.084,3.04,8.959,3.04c2.376,0,4.619-0.402,6.728-1.207c2.108-0.804,4.088-1.919,5.94-3.345c1.852-1.427,3.564-3.126,5.138-5.1
            c1.43-1.793,2.753-3.746,3.98-5.841c-0.078,0.366-0.174,0.732-0.24,1.097c-0.299,1.662-0.449,3.243-0.449,4.744
            c0,1.395,0.139,2.676,0.417,3.845s0.717,2.188,1.316,3.056c0.599,0.869,1.365,1.544,2.296,2.027
            c0.932,0.482,2.061,0.724,3.388,0.724c2.826,0,5.608-1.175,8.349-3.522c2.74-2.349,5.512-5.882,8.317-10.6
            c-0.3,1.051-0.525,2.063-0.674,3.04c-0.15,0.976-0.225,1.882-0.225,2.718c0,1.266,0.171,2.413,0.514,3.442
            c0.342,1.029,0.851,1.909,1.525,2.638c0.674,0.729,1.509,1.292,2.505,1.688c0.995,0.396,2.146,0.596,3.452,0.596
            c1.67,0,3.244-0.322,4.721-0.965c1.477-0.644,2.922-1.625,4.335-2.944c1.413-1.318,2.831-2.991,4.255-5.018
            c1.075-1.531,2.19-3.274,3.345-5.227c-0.205,0.724-0.38,1.433-0.519,2.122c-0.268,1.33-0.401,2.552-0.401,3.667
            c0,1.266,0.171,2.413,0.514,3.442c0.343,1.029,0.851,1.909,1.525,2.638c0.674,0.729,1.509,1.292,2.504,1.688
            c0.996,0.396,2.146,0.596,3.452,0.596c1.563,0,3.04-0.279,4.432-0.837c1.392-0.558,2.756-1.415,4.094-2.573
            s2.67-2.622,3.998-4.391c1.327-1.77,2.697-3.855,4.11-6.258l-6.294,17.308c-0.75,2.037-1.563,3.796-2.44,5.275
            c-0.878,1.479-1.857,2.697-2.938,3.651s-2.275,1.656-3.581,2.107c-1.306,0.45-2.74,0.675-4.303,0.675
            c-1.114,0-2.136-0.102-3.067-0.306c-0.931-0.203-1.739-0.493-2.424-0.868s-1.22-0.815-1.606-1.319
            c-0.385-0.504-0.578-1.056-0.578-1.656c0-0.172,0.016-0.284,0.048-0.338s0.112-0.08,0.241-0.08c0.172,0,0.327,0.032,0.466,0.097
            c0.14,0.063,0.327,0.096,0.562,0.096c0.557,0,1.017-0.214,1.381-0.643c0.364-0.43,0.546-0.987,0.546-1.673
            c0-0.729-0.214-1.298-0.642-1.705c-0.428-0.408-1.017-0.611-1.766-0.611c-0.878,0-1.568,0.278-2.071,0.836
            s-0.755,1.319-0.755,2.284c0,0.644,0.107,1.292,0.321,1.946c0.214,0.654,0.535,1.271,0.963,1.85
            c0.835,1.201,1.943,2.08,3.324,2.638c1.38,0.558,3.109,0.837,5.186,0.837c4.281,0,7.958-1.143,11.03-3.426
            c3.072-2.284,5.39-5.593,6.953-9.925l15.157-41.723h-4.785L155.41,36.427z M58.978,27.483c1.134-1.908,2.354-3.565,3.661-4.97
            c1.306-1.404,2.66-2.504,4.062-3.298c1.402-0.793,2.778-1.19,4.126-1.19c0.471,0,0.91,0.054,1.316,0.161s0.76,0.284,1.06,0.531
            c0.299,0.246,0.535,0.563,0.707,0.948c0.171,0.387,0.257,0.869,0.257,1.448c0,1.158-0.22,2.273-0.658,3.346
            c-0.439,1.072-1.017,2.069-1.734,2.991c-0.717,0.923-1.542,1.743-2.473,2.461c-0.931,0.719-1.89,1.303-2.874,1.753
            c-1.242,0.579-2.697,1.057-4.367,1.432c-1.67,0.376-3.682,0.585-6.037,0.628C56.858,31.473,57.842,29.393,58.978,27.483z
            M25.035,37.745c-0.835,1.716-1.75,3.384-2.746,5.003s-2.05,3.142-3.163,4.567c-1.113,1.427-2.264,2.67-3.452,3.731
            c-1.188,1.063-2.382,1.898-3.581,2.51c-1.199,0.611-2.376,0.917-3.532,0.917c-1.435,0-2.489-0.478-3.163-1.432
            s-1.011-2.269-1.011-3.94c0-1.48,0.251-3.324,0.754-5.533c0.503-2.209,1.257-4.611,2.264-7.206c1.22-3.109,2.563-5.817,4.03-8.123
            c1.466-2.305,2.959-4.214,4.48-5.726c1.52-1.513,3.013-2.639,4.479-3.378c1.466-0.74,2.799-1.11,3.998-1.11
            c0.599,0,1.183,0.097,1.75,0.29c0.567,0.192,1.07,0.521,1.509,0.981c0.438,0.461,0.792,1.062,1.06,1.801
            c0.268,0.74,0.401,1.657,0.401,2.751c0,1.201-0.166,2.558-0.498,4.069c-0.332,1.512-0.803,3.099-1.413,4.761
            C26.592,34.341,25.87,36.03,25.035,37.745z M106.889,37.745c-0.835,1.716-1.75,3.384-2.746,5.003s-2.05,3.142-3.163,4.567
            c-1.113,1.427-2.264,2.67-3.452,3.731c-1.188,1.063-2.382,1.898-3.581,2.51s-2.376,0.917-3.532,0.917
            c-1.435,0-2.489-0.478-3.163-1.432s-1.011-2.269-1.011-3.94c0-1.48,0.251-3.324,0.754-5.533s1.257-4.611,2.264-7.206
            c1.22-3.109,2.563-5.817,4.03-8.123c1.466-2.305,2.959-4.214,4.48-5.726c1.52-1.513,3.013-2.639,4.479-3.378
            c1.466-0.74,2.799-1.11,3.998-1.11c0.599,0,1.183,0.097,1.75,0.29c0.567,0.192,1.07,0.521,1.509,0.981
            c0.438,0.461,0.792,1.062,1.06,1.801c0.268,0.74,0.401,1.657,0.401,2.751c0,1.201-0.166,2.558-0.498,4.069
            c-0.332,1.512-0.803,3.099-1.413,4.761C108.446,34.341,107.724,36.03,106.889,37.745z M300.523,36.398l-1.668,2.955
            c-1.37,2.445-2.853,4.606-4.447,6.482c-1.596,1.877-3.275,3.453-5.042,4.729c-1.766,1.276-3.597,2.247-5.491,2.912
            c-1.895,0.664-3.838,0.997-5.828,0.997c-1.349,0-2.505-0.166-3.469-0.499c-0.963-0.332-1.75-0.853-2.359-1.561
            c-0.61-0.707-1.061-1.618-1.35-2.734c-0.289-1.114-0.434-2.466-0.434-4.053c0-2.102,0.242-4.246,0.723-6.434
            c0.482-2.188,1.141-4.306,1.976-6.354s1.814-3.973,2.938-5.774c1.123-1.802,2.322-3.372,3.596-4.713
            c1.273-1.34,2.591-2.396,3.95-3.169c1.359-0.771,2.702-1.158,4.03-1.158c0.514,0,1.012,0.06,1.493,0.178
            c0.481,0.117,0.904,0.278,1.269,0.482c0.363,0.204,0.652,0.439,0.867,0.707c0.214,0.269,0.321,0.542,0.321,0.82
            c0,0.279-0.097,0.419-0.289,0.419c-0.044,0-0.086-0.006-0.129-0.017s-0.118-0.037-0.225-0.08c-0.192-0.086-0.396-0.129-0.61-0.129
            c-0.6,0-1.081,0.215-1.445,0.644s-0.546,0.986-0.546,1.673c0,0.729,0.209,1.303,0.627,1.721c0.417,0.418,0.989,0.627,1.718,0.627
            c0.877,0,1.578-0.278,2.104-0.836c0.524-0.558,0.786-1.308,0.786-2.252c0-0.622-0.128-1.228-0.385-1.817s-0.632-1.131-1.124-1.625
            c-1.199-1.2-2.944-1.801-5.234-1.801c-2.184,0-4.33,0.504-6.438,1.512s-4.067,2.402-5.876,4.182
            c-1.811,1.78-3.416,3.893-4.817,6.338c-1.358,2.368-2.414,4.942-3.18,7.713l-1.589,2.87c-1.413,2.553-2.783,4.777-4.111,6.676
            c-1.327,1.897-2.648,3.474-3.965,4.729s-2.649,2.188-3.998,2.799c-1.35,0.611-2.74,0.917-4.175,0.917
            c-1.649,0-2.863-0.439-3.645-1.319c-0.782-0.879-1.173-2.059-1.173-3.538c0-0.665,0.07-1.389,0.209-2.172
            c0.139-0.782,0.321-1.598,0.547-2.444c0.225-0.848,0.486-1.705,0.786-2.574c0.3-0.868,0.599-1.721,0.899-2.558l8.188-22.518
            h-4.753l-2.602,7.142c0-1.158-0.107-2.215-0.32-3.169c-0.215-0.954-0.573-1.77-1.076-2.445c-0.503-0.675-1.161-1.2-1.975-1.576
            c-0.814-0.375-1.83-0.563-3.051-0.563c-1.178,0-2.436,0.172-3.773,0.515s-2.697,0.863-4.078,1.56
            c-1.381,0.697-2.762,1.582-4.143,2.654s-2.697,2.349-3.949,3.828c-1.253,1.479-2.414,3.169-3.484,5.066
            c-1.044,1.853-1.944,3.906-2.703,6.156l-1.6,2.835c-1.478,2.617-2.896,4.874-4.256,6.771c-1.359,1.898-2.691,3.464-3.998,4.697
            c-1.306,1.233-2.601,2.15-3.885,2.75c-1.285,0.601-2.58,0.901-3.886,0.901c-1.627,0-2.858-0.407-3.693-1.223
            c-0.835-0.814-1.252-1.876-1.252-3.185c0-0.751,0.117-1.518,0.353-2.301c0.235-0.782,0.498-1.56,0.787-2.332
            c0.289-0.771,0.551-1.527,0.787-2.268c0.235-0.74,0.353-1.453,0.353-2.14c0-1.544-0.701-2.819-2.103-3.828
            c-1.402-1.008-3.42-1.597-6.053-1.769v-0.129c1.327-0.322,2.505-0.638,3.532-0.949c1.028-0.311,1.948-0.616,2.762-0.917
            c0.813-0.3,1.536-0.6,2.167-0.9c0.631-0.3,1.215-0.622,1.75-0.965c1.67-1.094,2.949-2.381,3.838-3.86
            c0.888-1.48,1.332-3.121,1.332-4.922c0-2.145-0.647-3.785-1.942-4.922c-1.296-1.137-3.163-1.705-5.604-1.705
            c-1.477,0-2.864,0.246-4.159,0.739c-1.295,0.494-2.59,1.319-3.886,2.478s-2.639,2.702-4.03,4.632
            c-1.392,1.931-2.922,4.322-4.592,7.174l11.208-30.785h-4.785l-20.006,55.073h4.753l6.743-18.465
            c2.12-3.86,3.998-7.056,5.636-9.587c1.638-2.53,3.142-4.541,4.512-6.031s2.66-2.53,3.87-3.121
            c1.209-0.589,2.446-0.884,3.709-0.884c3.211,0,4.817,1.736,4.817,5.211c0,2.917-1.188,5.458-3.564,7.624
            c-1.22,1.094-2.725,2.103-4.512,3.024c-1.788,0.922-3.805,1.694-6.053,2.315v0.161c2.248,0.064,3.907,0.547,4.978,1.447
            c1.07,0.901,1.605,2.017,1.605,3.346c0,0.687-0.112,1.427-0.337,2.22c-0.225,0.794-0.471,1.614-0.739,2.461
            c-0.268,0.848-0.514,1.716-0.739,2.605c-0.225,0.891-0.337,1.765-0.337,2.622c0,1.094,0.203,2.006,0.61,2.734
            c0.407,0.729,0.953,1.309,1.638,1.737s1.478,0.734,2.376,0.917c0.899,0.182,1.841,0.273,2.826,0.273
            c1.67,0,3.232-0.258,4.688-0.772c1.455-0.515,2.906-1.388,4.352-2.622c1.445-1.232,2.916-2.884,4.415-4.953
            c1.39-1.919,2.895-4.304,4.505-7.117c-0.076,0.356-0.17,0.713-0.234,1.069c-0.299,1.662-0.449,3.243-0.449,4.744
            c0,1.395,0.14,2.676,0.418,3.845s0.717,2.188,1.316,3.056c0.6,0.869,1.365,1.544,2.296,2.027c0.931,0.482,2.06,0.724,3.388,0.724
            c2.826,0,5.609-1.175,8.35-3.522c2.74-2.349,5.512-5.882,8.316-10.6c-0.3,1.051-0.524,2.063-0.674,3.04
            c-0.15,0.976-0.225,1.882-0.225,2.718c0,1.266,0.171,2.413,0.514,3.442c0.342,1.029,0.851,1.909,1.525,2.638
            c0.674,0.729,1.51,1.292,2.504,1.688c0.996,0.396,2.146,0.596,3.453,0.596c1.67,0,3.242-0.317,4.721-0.949
            c1.477-0.633,2.922-1.608,4.334-2.928c1.414-1.318,2.832-2.991,4.256-5.018c1.423-2.027,2.916-4.435,4.479-7.223l0.026-0.047
            c-0.027,0.176-0.065,0.354-0.09,0.529c-0.171,1.223-0.257,2.413-0.257,3.571c0,3.925,1.001,6.916,3.002,8.975
            c2.002,2.06,4.994,3.089,8.976,3.089c4.389,0,8.435-1.324,12.139-3.973c3.199-2.288,6.158-5.625,8.878-10.003l-6.245,17.225
            c-0.771,2.123-1.498,3.925-2.184,5.404c-0.685,1.479-1.375,2.687-2.071,3.619c-0.695,0.933-1.407,1.613-2.135,2.043
            c-0.728,0.429-1.51,0.643-2.345,0.643c-0.964,0-1.707-0.252-2.231-0.756s-0.787-1.217-0.787-2.139
            c0-0.215,0.021-0.349,0.064-0.402s0.117-0.08,0.225-0.08c0.193,0,0.359,0.032,0.498,0.096c0.139,0.064,0.326,0.097,0.563,0.097
            c0.557,0,1.011-0.22,1.364-0.659c0.353-0.439,0.53-1.003,0.53-1.688c0-0.708-0.215-1.266-0.643-1.673
            c-0.428-0.408-1.017-0.611-1.767-0.611c-0.92,0-1.643,0.306-2.167,0.917c-0.525,0.61-0.787,1.463-0.787,2.557
            c0,1.866,0.461,3.287,1.381,4.263s2.237,1.464,3.949,1.464c2.72,0,5.234-1.143,7.547-3.426c2.313-2.284,4.261-5.593,5.845-9.925
            l15.124-41.723h-4.752L300.523,36.398z M241.856,37.745c-0.835,1.716-1.75,3.384-2.745,5.003
            c-0.996,1.619-2.051,3.142-3.164,4.567c-1.113,1.427-2.264,2.67-3.451,3.731c-1.188,1.063-2.383,1.898-3.581,2.51
            c-1.199,0.611-2.376,0.917-3.532,0.917c-1.435,0-2.488-0.478-3.163-1.432s-1.012-2.269-1.012-3.94c0-1.48,0.251-3.324,0.755-5.533
            c0.503-2.209,1.258-4.611,2.264-7.206c1.221-3.109,2.563-5.817,4.03-8.123c1.466-2.305,2.96-4.214,4.479-5.726
            c1.52-1.513,3.014-2.639,4.48-3.378c1.465-0.74,2.799-1.11,3.998-1.11c0.598,0,1.182,0.097,1.75,0.29
            c0.566,0.192,1.07,0.521,1.509,0.981s0.792,1.062,1.06,1.801c0.268,0.74,0.401,1.657,0.401,2.751c0,1.201-0.166,2.558-0.497,4.069
            c-0.333,1.512-0.803,3.099-1.414,4.761C243.414,34.341,242.691,36.03,241.856,37.745z M312.729,6.188
            c-0.75,0-1.387,0.269-1.911,0.805s-0.787,1.18-0.787,1.93c0,0.751,0.257,1.378,0.771,1.882c0.514,0.504,1.145,0.756,1.895,0.756
            c0.385,0,0.743-0.069,1.075-0.209s0.621-0.327,0.867-0.563c0.246-0.236,0.438-0.521,0.578-0.853
            c0.139-0.333,0.209-0.681,0.209-1.046c0-0.75-0.263-1.389-0.787-1.914S313.478,6.188,312.729,6.188z M353.768,36.395l-1.67,2.959
            c-1.07,1.888-2.323,3.737-3.757,5.55c-1.435,1.813-3.019,3.426-4.753,4.841c-1.734,1.416-3.618,2.558-5.651,3.427
            c-2.034,0.868-4.175,1.303-6.423,1.303c-2.697,0-4.672-0.66-5.925-1.979c-1.252-1.319-1.879-3.469-1.879-6.45
            c0-1.951,0.177-3.865,0.53-5.742c0.354-1.876,0.84-3.684,1.461-5.42c2.569,0,4.859-0.178,6.872-0.531s3.854-0.885,5.523-1.593
            c1.349-0.557,2.563-1.217,3.645-1.978c1.081-0.762,2.007-1.582,2.778-2.461c0.771-0.88,1.358-1.813,1.766-2.799
            c0.406-0.986,0.61-1.995,0.61-3.024c0-1.822-0.616-3.238-1.847-4.246c-1.231-1.008-2.917-1.512-5.058-1.512
            c-2.119,0-4.229,0.504-6.326,1.512c-2.099,1.008-4.057,2.402-5.876,4.182c-1.82,1.78-3.432,3.893-4.833,6.338
            c-1.402,2.444-2.489,5.104-3.26,7.978c-0.342,1.287-0.594,2.541-0.755,3.764c-0.16,1.223-0.24,2.381-0.24,3.475
            c0,4.011,1.049,7.029,3.146,9.056s5.085,3.04,8.96,3.04c2.376,0,4.618-0.402,6.728-1.207c2.108-0.804,4.088-1.919,5.94-3.345
            c1.852-1.427,3.564-3.126,5.138-5.1c1.573-1.973,3.023-4.128,4.352-6.466l1.67-2.959L353.768,36.395z M329.041,27.483
            c1.135-1.908,2.354-3.565,3.661-4.97c1.306-1.404,2.659-2.504,4.062-3.298c1.402-0.793,2.778-1.19,4.127-1.19
            c0.471,0,0.909,0.054,1.316,0.161c0.406,0.107,0.76,0.284,1.06,0.531c0.3,0.246,0.535,0.563,0.707,0.948
            c0.171,0.387,0.257,0.869,0.257,1.448c0,1.158-0.22,2.273-0.658,3.346c-0.439,1.072-1.018,2.069-1.734,2.991
            c-0.718,0.923-1.541,1.743-2.473,2.461c-0.932,0.719-1.89,1.303-2.874,1.753c-1.242,0.579-2.697,1.057-4.367,1.432
            c-1.67,0.376-3.683,0.585-6.037,0.628C326.922,31.473,327.906,29.393,329.041,27.483z"/>
    </g>
    <g>
        <path fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M332.742,74.424c-0.576,0-0.864,0.321-0.864,0.963
            c0,0.644,0.288,0.964,0.864,0.964s0.864-0.32,0.864-0.964C333.606,74.745,333.318,74.424,332.742,74.424z M350.335,71.001
            c0,0.538,0.006,1.124,0.02,1.761c0.013,0.636,0.032,1.157,0.059,1.563h-0.078l-8.09-12.234h-1.217v14.044h1.06v-8.852
            c0-0.629-0.007-1.229-0.02-1.8s-0.032-1.111-0.059-1.623h0.059l8.109,12.274h1.218V62.091h-1.061V71.001z M359.269,76.135h7.696
            v-1.023h-6.558V69.27h6.185v-1.003h-6.185v-5.153h6.558v-1.022h-7.696V76.135z M372.208,62.091v1.022h4.516v13.021h1.139V63.113
            h4.517v-1.022H372.208z"/>
    </g>
    </svg>


</template>

<style scoped lang="scss">

header {
    .logo {

        width: 70vw;
        max-width: 400px;
        height: auto;
        margin-top: 1rem;
        margin-bottom: 2rem;

    }

}

footer {
    .logo {

        width: 200px;
        max-width: 70vw;
        height: auto;

        g  {            
            path {
                fill:#bbb7b6
            }            
        }

        g:nth-child(1)  {            
            path {
                fill:#bbb7b6;
                opacity: 0.3;
            }            
        }


    }

}    

</style>