<template>

  <header>

    <div class="wrapper">

      
      <router-link :to="{ name: 'home' }" title="Dedykacje, życzenia, wierszyki na różne okazje: Chrzest, I Komunię, Ślub..."><LogoHeader /></router-link>
      <!-- <router-link :to="{ name: 'about' }">About</router-link> -->
      

      <div class="search-bar">
        <input id="searchInput" v-model="searchValue" v-on:input="searchTriggerFn" placeholder="szukaj" />
      </div>

      <InfoBox :error="error" :loading="loading" />

      <nav v-if="result && result.tags" class="tags">
        <router-link v-for="tag in result.tags" :key="tag.id" :to="{ name: 'dedicationsByTagLink', params: { tagLink: tag.link }}" :title="`Dedykacje, życzenia, wierszyki ${tag.title.charAt(0).toLowerCase()}${tag.title.slice(1)}`">
          {{ tag.title }}
        </router-link>
      </nav>

    </div>

  </header>

</template>





<script setup>

  import gql from 'graphql-tag';
  import { ref, watch, defineEmits } from 'vue';
  import { useQuery } from '@vue/apollo-composable';
  import { useRouter } from 'vue-router';
  import { useRoute } from 'vue-router';  
  import debounce from "lodash.debounce";

  import LogoHeader from '@/components/LogoHeader.vue';
  import InfoBox from '@/components/InfoBox.vue';

  const TAGS_QUERY = gql`
    query {
      tags(orderBy: {order: asc}) {
        id,
        name,
        title,
        link,
        order
      }
    }
  `

  const router = useRouter();
  const route = useRoute();

  const emit = defineEmits(['searchValue'])

  const { result, loading, error } = useQuery(TAGS_QUERY);

  let searchValue = ref(route.params.searchValue);

  watch(
      () => route.params.searchValue,
      () => searchValue.value = route.params.searchValue,
  )

  const searchTriggerFn = debounce(() => { 

    searchValue.value ? emit('searchValue', searchValue.value) : router.push(`/`);

  }, 1500);


</script>














<!-- <script>

  import gql from 'graphql-tag';
  import { ref, watch } from 'vue';
  import { useQuery } from '@vue/apollo-composable';
  import { useRouter } from 'vue-router';
  import { useRoute } from 'vue-router';  
  import debounce from "lodash.debounce";

  import LogoHeader from '@/components/LogoHeader.vue';
  import InfoBox from '@/components/InfoBox.vue';

  const TAGS_QUERY = gql`
    query {
      tags(orderBy: {order: asc}) {
        id,
        name,
        title,
        link,
        order
      }
    }
  `

  export default {

		name: 'PageHeader',
    components: {
      LogoHeader,
      InfoBox,
    },    
    emits: ['searchValue'],
		
		setup (props, {emit}) {

      const router = useRouter();
      const currentLink = ref();
      const route = useRoute();

			const { result, loading, error } = useQuery(TAGS_QUERY);

      let searchValue = ref(route.params.searchValue);

      watch(
          () => route.params.searchValue,
          () => searchValue.value = route.params.searchValue,
      )

      const searchTriggerFn = debounce(() => { 

        if (searchValue.value != '') {
          emit('searchValue', searchValue.value);
        } else {
          router.push(`/`)
        }

      }, 1500);

			return {
				result,
				loading, 
				error,
        currentLink,
        searchValue,
        searchTriggerFn,
			}

    }

	}

</script> -->


<style scoped lang="scss">


header {

  max-width: $layout-max-width;
  width: $layout-width;
  margin: 0 auto;
  text-align: center;
  padding: 2rem 0;

  .wrapper {

    display: flex;
    flex-direction: column;

  }



  .search-bar {
    margin-bottom: 2rem; 
    
    input {
      background: none;
      border: none;
      border-bottom: 2px solid $lead-color;
      text-align: center;
      padding: 0.5rem;

      &::placeholder {
        @include font(0.8rem, 300, 1, $text-font-1, $lead-color);
        text-transform: uppercase;
        letter-spacing: 0.3rem;      
      }

      &:focus {
        outline: none;
      }

    }

  }

  .info {
    position: static;
  }

  .tags {

    list-style-type: none;
    padding: 1rem 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;    
    row-gap: 0.5rem;
    column-gap: 1rem;
    flex-wrap: wrap;

    a {
      text-decoration: none;
      padding: 0.5rem 1rem;    
      @include font(0.9rem, 400, 1.3, $text-font-1, $lead-color);
      text-transform: uppercase;
      letter-spacing: 0.2rem;
      border-radius: 8px;
      transition: background-color 0.3s;
      display: block;

      &.router-link-exact-active {
        color: #fff;
        background-color: $contrast-color;
      }

      &:hover:not(.router-link-exact-active) {
        background-color: rgba($color: #000000, $alpha: 0.05);
      }

    }

  }

}



</style>












// const sortedData = ref([]);

// const sortData = () => {
//   sortedData.value = result.value.tags.slice().sort((a,b) => b.order - a.order);
//   console.log(sortedData.value);
// };


// watch(result, () => sortData());

// watch(result, data => 
//   tagName.value = data.tags.filter(el => el.link == route.params.tagLink)[0]
// );    


//const allTags = ref(result);
// watch(
//     () => route.params.tagLink,
//     () => {
//         emit('tag', { name: route.params.tagLink ? allTags.value.tags.filter(el => el.link == route.params.tagLink)[0].name : '' });
//     }
// )

//watch(props, () => currentLink.value = props.tagLink);

// const redirectByTagLink = (tagLink, tagId, tagName) => {

// emit('tag', {
//     id: tagId,
//     name: tagName,
//     link: tagLink,
// })
  
//   router.push({
//     name: 'dedicationsByTagLink',
//     params: { 
//       tagLink,            
//     } 
//   });

// }


// const CURRENT_TAG_QUERY = gql`
//   query getTag($link: String) {
//     tags(where: { tags: { some: { link: { equals: $link } } } }) {
//       id,
//       name,
//       title,
//       link
//     }
//   }
// `

