import { createApp, provide, h } from 'vue'
import { DefaultApolloClient } from '@vue/apollo-composable'
import { ApolloClient, InMemoryCache } from '@apollo/client/core'
import App from './App.vue'
import router from './router'
import store from './store'
import { createHead } from '@unhead/vue'
import VueGtag from "vue-gtag";


const cache = new InMemoryCache()

const apolloClient = new ApolloClient({
  cache,
  //uri: 'http://localhost:3000/api/graphql',
  //uri: 'https://admin-production-b8c1.up.railway.app/api/graphql',
  //uri: 'https://p01--dedykacje-admin--jzxx9dh2m8l9.code.run/api/graphql',
  //uri: 'https://dedykacje-admin.onrender.com/api/graphql',
  uri: process.env.VUE_APP_CONNECT_URI,
})

const app = createApp({
  setup () {
    provide(DefaultApolloClient, apolloClient)
  },

  render: () => h(App),
})

const head = createHead();

app.use(router);
app.use(store);
app.use(head);
app.use(VueGtag,{
  config: { id: "G-PTKZD3T93R" },
  globalObjectName: 'gtag',
  router,
  enabled: true,
});

app.provide('gtag', app.config.globalProperties.$gtag);

app.config.globalProperties.$domain = process.env.VUE_APP_DOMAIN;


router.isReady().then(() => {
  app.mount('#app')
})


//app.mount('#app');
