<template>

    <div id="cookiesPopup" ref="cookiesPopup" :class='{on: cookiesPopupOn}'>

        <p>Strona korzysta z plików cookie w celu realizacji usług zgodnie z Polityką prywatności. Możesz określić warunki przechowywania lub dostępu do cookie w Twojej przeglądarce.</p>
        <div>
            <button @click="closeCookiesPopup('reject')" title="Zamknij informację o plikach cookies" class="reject">odrzucam</button>  
            <button @click="closeCookiesPopup('accept')" title="Zamknij informację o plikach cookies" class="accept">akceptauję</button>
        </div>

    </div>


</template>
  
  
<script setup>
  
    import { ref, onMounted } from 'vue';
    import { useCookies } from "vue3-cookies";
    //import { optIn, optOut } from 'vue-gtag';
  
  
      //inject: ['gtag'],
  
  
  
    const cookiesPopup = ref();
    const { cookies } = useCookies();
    let cookiesPopupOn = ref(false);
  
    const closeCookiesPopup = function(opt) {
  
        switch(opt) {

            case 'reject': 
                cookies.set("gdpr-consent", false, "14d");
                //optOut();
                break;

            case 'accept':
                cookies.set("gdpr-consent", true, "14d");
                //optIn();
                break;

        }
            
        cookiesPopupOn.value = false;
  
    }
  
    onMounted(() => {

        if (!cookies.get("gdpr-consent")) {
        cookiesPopupOn.value = true;
        }

    });
  

</script>
  
  
<style lang="scss">

    #cookiesPopup {

        max-width: $layout-max-width;
        width: 96%;
        min-width: 240px;
        bottom: 2vh;
        margin: 0 auto;
        position: fixed;
        background-color: #fff;
        text-align: center;
        z-index: 997;
        left: 50%;
        transform: translateX(-50%) translateY(calc(100% + 2vh));
        -webkit-transition: -webkit-transform 0.2s;
        -moz-transition: -moz-transform 0.2s;
        transition: transform 0.2s;
        @include font(0.8rem, 300, 1.3, $text-font-1, $text-color);
        border-radius: 20px;
        -webkit-box-shadow: 0px 3px 11px -7px rgba(0, 0, 0, 1);
        -moz-box-shadow: 0px 3px 11px -7px rgba(0, 0, 0, 1);
        box-shadow: 0px 3px 11px -7px rgba(0, 0, 0, 1);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        box-sizing: border-box;

    &.on {
        transform: translateX(-50%) translateY(0);
    }

    p {      
        //margin: 1.5em 0 1em;
        margin: 0 1.5em 0 0;
    }
  
    button {

        border: 0;
        cursor: pointer;
        padding: 0.5em 1em;
        @include font(0.9em, 500, 1, $text-font-1, $text-color);
        //margin-bottom: 1.5em;
        min-width: 85px;
        box-sizing: border-box;
        border: 1px solid $lead-color;

        &.accept {
            background-color: $lead-color;
            color: #fff;
        }

        &.reject {
            background: #fff;            
        }

    }
  
  
  
    @media screen and (max-width:600px) {

        width: 92%;
        font-size: 0.65rem;

    }

}

</style>