<template>

    <div class="info" :class="{on: loading || error}">
        <p v-if="error">coś poszło nie tak... {{ error }}</p>
        <div v-if="loading" class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    </div>

</template>

<script setup>

    import { defineProps, toRefs } from "vue";

    const props = defineProps({
        error: Object,
        loading: Boolean,
    })

    const { error, loading } = toRefs(props);


</script>


<style scoped lang="scss">

.info {

    width: 100%;
    text-align: center;
    @include font(1.2rem, 700, 1.5, $text-font-2, rgba(0,0,0,0.2));
    font-style: italic;
    opacity: 0;
    transition: opacity 0.3s;

    &.on {
        opacity: 1;
    }


    @keyframes lds-ellipsis1 {
        0% {
            transform: scale(0);
        }
        100% {
            transform: scale(1);
        }
    }
    @keyframes lds-ellipsis3 {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(0);
        }
    }
    @keyframes lds-ellipsis2 {
        0% {
            transform: translate(0, 0);
        }
        100% {
            transform: translate(24px, 0);
        }
    }
    .lds-ellipsis {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
        div {
            position: absolute;
            top: 33px;
            width: 13px;
            height: 13px;
            border-radius: 50%;
            background: #fff;
            animation-timing-function: cubic-bezier(0, 1, 1, 0);
            &:nth-child(1) {
                left: 8px;
                animation: lds-ellipsis1 0.6s infinite;
            }
            &:nth-child(2) {
                left: 8px;
                animation: lds-ellipsis2 0.6s infinite;
            }
            &:nth-child(3) {
                left: 32px;
                animation: lds-ellipsis2 0.6s infinite;
            }
            &:nth-child(4) {
                left: 56px;
                animation: lds-ellipsis3 0.6s infinite;
            }
        }
    }



}


</style>